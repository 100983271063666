import React from 'react';
import './App.css';
import WeatherDaily from './components/WeatherHourly';
class App extends React.Component {
  render(){
    return(
      <div className="bg-weather">
        <WeatherDaily />
      </div>
    )
  }
}

export default App;
