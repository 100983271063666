const API = {
  BASE_URL: `https://api.openweathermap.org/data/2.5/`,
  END_POINT_HOURLY: `forecast/hourly`,
  TOKEN: `da0e318349571630e9c4e98cbf69c2a3`,

};

async function getWeathersHourly(lat = "21.0307742", long = "105.7805666") {
  const TIME_ZONE_OFFSET = 7 * 60 * 60;
  const URL_HOURLY = `${API.BASE_URL}/onecall?lat=${lat}&lon=${long}&units=metric&lang=vi&timezone_offset=${TIME_ZONE_OFFSET}&appid=${API.TOKEN}`;
  return await fetch(URL_HOURLY).then((res) => res.json());
}

async function getWeathersByCity(cityName = "hanoi") {
  const URL_HOURLY = `${API.BASE_URL}/weather?q=${cityName}&appid=${API.TOKEN}`;
  return await fetch(URL_HOURLY).then((res) => res.json());
}

async function getCoordinateByAddress(address) {
  const URL = `https://maps.googleapis.com/maps/api/geocode/json?address=${address}&key=AIzaSyAphNLC_y7uhPIhYAeLHtyHcFsiM1_2los`;
  return await fetch(URL).then((res) => res.json());
}

async function getPlaces(text) {
  const URL = `https://maps.googleapis.com/maps/api/place/findplacefromtext/json?input=${text}&inputtype=textquery&language=vi&locationbias=ipbias&key=AIzaSyBfDRtsrCYEmlnAnAHmmPJncVZcHn-cINg`;
  return await fetch(URL).then((res) => res.json());
}

export default {
  getWeathersHourly,
  getWeathersByCity,
  getCoordinateByAddress,
  getPlaces,
};
