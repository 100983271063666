import React, { Component } from "react";
import apiWeathers from "./../apis/weathers";

class SearchBar extends Component {

  onChangeFilterText(event){
    this.props.handleFilterText(event.target.value)
  }

  render(){
    return (
      <div className={this.props.isDisplayAutoComplete ? "wrap-search-bar show-autocomplete" : "wrap-search-bar" }>
        <input type="text" name="filterText" className="filter-text" placeholder="Enter city ..." onChange={(event)=>this.onChangeFilterText(event)} />
        <ul className="autocomplete">
          <li><a href="#hanoi">Hà Nội - Việt Nam</a></li>
          <li><a href="#hochiminh">TP Hồ Chí Minh - Việt Nam</a></li>
          <li><a href="#haiphong">Hải Phòng - Việt Nam</a></li>
          <li><a href="#bacninh">Bắc Ninh - Việt Nam</a></li>
        </ul>
      </div>
    )
  }
}

class CurrentWeather extends Component {
  renderDate(date) {
    const dateFormat = new Date(date).toLocaleDateString("vi", {
      weekday: "long",
      day: "numeric",
      month: "numeric",
    });
    return <p>{dateFormat}</p>;
  }
  renderIcon(iconCode, description = "Weather icon") {
    var iconUrl = "http://openweathermap.org/img/w/" + iconCode + ".png";
    return <img className="currentIcon" src={iconUrl} alt={description} />;
  }
  render() {
    const { weatherItem } = this.props;
    return (
      <div className="self-center">
        <div className="current-date">
          {weatherItem && this.renderDate(weatherItem.dt * 1000)}
        </div>
        <div className="current-weather">
          <div className="current-weather-icon">
            {weatherItem && this.renderIcon(weatherItem.weather[0].icon)}
          </div>
          <div className="current-weather-info">
            <div className="current-temp">
              {weatherItem && weatherItem.temp}
            </div>
            <div className="current-description">
              {weatherItem && weatherItem.weather[0].description}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

class WeatherItem extends Component {
  renderIcon(iconCode, description = "Weather icon") {
    var iconUrl = "http://openweathermap.org/img/w/" + iconCode + ".png";
    return <img src={iconUrl} alt={description} />;
  }

  renderDate(date) {
    const dateFormat = new Date(date).toLocaleDateString("vi", {
      weekday: "long",
      day: "numeric",
      month: "numeric",
    });
    return <p>{dateFormat}</p>;
  }

  renderTime(date) {
    const dateFormat = new Date(date).toLocaleTimeString("vi", {
      hour: "numeric",
      minute: "numeric",
    });
    return <p>{dateFormat}</p>;
  }

  renderHumidity(humidity) {
    return <p>Độ ẩm: {humidity} %</p>;
  }

  render() {
    const { weatherItem, daily } = this.props;
    return (
      <div className="weather-hourly">
        {this.renderIcon(
          weatherItem.weather[0].icon,
          weatherItem.weather[0].description
        )}
        {this.renderDate(weatherItem.dt * 1000)}
        {!daily && this.renderTime(weatherItem.dt * 1000)}
        <p>Nhiệt độ: {daily ? weatherItem.temp.day : weatherItem.temp} độ C</p>
        <p>Mô tả: {weatherItem.weather[0].description}</p>
        {!daily && this.renderHumidity(weatherItem.humidity)}
      </div>
    );
  }
}

class WeathersHourly extends Component {
  render() {
    const hourlyList = [];
    const { weatherHourly } = this.props;
    weatherHourly &&
      weatherHourly.forEach((weather, i) => {
        hourlyList.push(<WeatherItem weatherItem={weather} key={i} />);
      });
    return <div className="wrap-weather-hourly scroll-style">{hourlyList}</div>;
  }
}

class WeatherDaily extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      weathers: {},
    };
  }

  handleFilterText(filterText){
    //call api search, show autocomplete
    console.log("filter Text",filterText);
  }

  searchWeather = () => {
    apiWeathers.getPlaces("17 Duy").then(data => {
      console.log("data", data);
    })

    apiWeathers.getWeathersHourly().then((data) => {
      this.setState({ weathers: data });
    });
  };

  componentDidMount() {
    this.searchWeather();
  }

  render() {
    const dailyList = [];
    const { daily } = this.state.weathers;
    daily &&
      daily.length > 0 &&
      daily.forEach((day, i) => {
        dailyList.push(<WeatherItem weatherItem={day} key={i} daily={true} />);
      });

    return (
      <div className="flex-height">
        {/* <div className="heightSearchBar">
          <SearchBar handleFilterText={(event) => this.handleFilterText(event)} isDisplayAutoComplete={true} />
        </div> */}
        <div className="heightItem flex">
          <CurrentWeather weatherItem={this.state.weathers.current} />
        </div>
        <div className="height-item">
          <div className="forecast">Dự báo thời tiết 1 tuần tới</div>
          <div className="wrap-weather-daily scroll-style main-distance-bottom">
            {dailyList}
          </div>
        </div>

        <div className="height-item">
          <div className="forecast">Dự báo thời tiết 2 ngày tới theo giờ</div>
          <WeathersHourly
            weatherHourly={this.state.weathers.hourly}
            daily={false}
          />
        </div>
      </div>
    );
  }
}

export default WeatherDaily;
